<template>
  <div class="solutionIndex page_body_con">
    <div class="nav">
      <div class="page_location">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item>当前位置:</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/' }">
            <i class="el-icon-s-home"></i> 首页</el-breadcrumb-item
          >
          <el-breadcrumb-item :to="{ path: '/solution' }"
            >解决方案</el-breadcrumb-item
          >
          <el-breadcrumb-item>智慧冷链</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <div class="margin-tb-5">
      <el-row>
        <el-col :span="6" class="left">
          <div class="page_title_name">
            <h3>智慧冷链</h3>
            <span>
              Smart cold chain
            </span>
       
          </div>
        </el-col>
        <el-col :span="18">
          <div class="desc">
           智慧冷库是安全、节能、便捷的环保型绿色冷库，利用互联网技术实现冷库设备的智能化管理，保证设备常年处于最佳运行工况，杜绝不良运行、带病运行现场，并通过线上、线下资源整合实现对传统行业设备管理维修体系的技术革新与改造。响应国家对节能、环保、降耗、高效发展的号召，智慧冷库将成为未来冷库企业的发展方向。</div>
        </el-col>
      </el-row>
 <div class="slideshow">
        <div class="swiper-container">
          <div class="swiper-wrapper">
            <div
              v-for="(item, i) in list"
              :key="i"
              class="swiper-slide"
              @click="bindDetail(item)"
            >
              <!-- 具体内容 -->

              <img :src="item.pic" />
              <div class="slideshowtext">
                <h6>{{ item.title }}</h6>
                <span class="item_desc">{{ item.desc }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="swiper-button-next swiper-button-white">
          <i class="el-icon-arrow-right"></i>
        </div>
        <div class="swiper-button-prev swiper-button-black">
          <i class="el-icon-arrow-left"></i>
        </div>
      </div>
      </div>
      <div class="custom_box">
        <!-- <p></p> -->
        <el-card shadow="always"> 联系我们定制您的业务需求 </el-card>
        <button class="button_yiliao" @click="bindCustomized">定制</button>
      </div>
    </div>
  </div>
</template>

<script>
// 引入swiper插件的js
import Swiper from "@/tools/swiper.min.js";
import "@/tools/swiper.min.css";
export default {
  data() {
    return {
      type: 3,
      page: 1,
      limit: 100,
      total: 0,
      list: [],
      pictures: [
        {
          advertiseImages: require("@/assets/images/solution/coldchain1.png"),
          text: "海报名称一",
          desc: "方案一的简介，内容方案一的简介内容方案一的简介，内容方案。一的简介内容方案一的简介内...",
        },
        {
          advertiseImages: require("@/assets/images/solution/coldchain2.png"),
          text: "海报名称二",
          desc: "方案一的简介，内容方案一的简介内容方案一的简介，内容方案。一的简介内容方案一的简介内...",
        },
        {
          advertiseImages: require("@/assets/images/solution/coldchain3.png"),
          text: "海报名称三",
          desc: "方案一的简介，内容方案一的简介内容方案一的简介，内容方案。一的简介内容方案一的简介内...",
        },
        {
          advertiseImages: require("@/assets/images/solution/coldchain1.png"),
          text: "海报名称四",
          desc: "方案一的简介，内容方案一的简介内容方案一的简介，内容方案。一的简介内容方案一的简介内...",
        },
        {
          advertiseImages: require("@/assets/images/solution/coldchain2.png"),
          text: "海报名称五",
          desc: "方案一的简介，内容方案一的简介内容方案一的简介，内容方案。一的简介内容方案一的简介内...",
        },
        {
          advertiseImages: require("@/assets/images/solution/coldchain3.png"),
          text: "海报名称6",
          desc: "方案一的简介，内容方案一的简介内容方案一的简介，内容方案。一的简介内容方案一的简介内...",
        },
      ],
    };
  },
  activated() {
    // window.scrollTo(0, 0);
    this.$nextTick(() => {
      this.myswiper();
    });
  },
  watch: {
    $route: {
      handler: function (route) {
        if (route.path == "/solution/coldchain/index") {
          this.type = route.query.type==undefined?3:route.query.type;
          this.page = 1;
          this.getSolutionList();
        }
      },
      immediate: true,
    },
  },
  methods: {
    getSolutionList() {
      this.list=[]
      this.$http({
        method: "get",
        url: "website_content_list",
        params: {
          limit: this.limit,
          page: this.page,
          type: 2,
          type_id: this.type,
        },
      }).then((res) => {
        console.log(res);
        if (res.code == 0) {
          this.list = res.data.data;
          this.total = res.data.count;
        }
      });
    },
    //点击定制
    bindCustomized() {
      this.$router.push({
        path: "/introduce/contactus",
        query: { type: 1 },
      });
    },
    bindDetail(e) {
      console.log(e);
      this.$router.push({
        name: "coldchainDetail",
        query:({
          id:e.id
        })
      });
    },
    myswiper: function () {
      var swiper = new Swiper(".swiper-container", {
        loop: true,
        speed: 1000,
        // slidesPerView: "4",
        slidesPerView: 4,
        loopedSlides: 4,

        preventClicksPropagation: false, //阻止默认事件
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        autoplay: {
          delay: 3000, //3秒切换一次
          pauseOnMouseEnter: true, //悬停停止切换
        },
        lazy: {
          loadPrevNext: true,
          loadPrevNextAmount: 2,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.slideshow {
  width: 100%;
  overflow: hidden;
  margin-top: 0.63rem;
  
}

.slideshowtext {
  width: 350px;
  text-align: left;
  font-size: 14px;
  color: #fff;
  padding: 15px 10px;
  background: #006acd;
  h6 {
    font-weight: bold;
    color: #ffffff;
    font-size: 24px;
    height: 34px;
    line-height: 34px;
    margin-bottom: 5px;
  }
    .item_desc {
    margin: 5px 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; // 控制多行的行数
    -webkit-box-orient: vertical;
  }
}
// .swiper-container {
//   width: 350px;
// }
.swiper-wrapper {
  width: 100%;
  height: 100%;
    
}
.swiper-slide {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  font-size: 18px;

  width: 100%;
  height: 100%;

  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  transition: 500ms;
  cursor: pointer;
  transform: scale(0.8);
}

img {

  width: 350px;
  height: 240px;
}
.swiper-slide-active,
.swiper-slide-duplicate-active {
  transform: scale(0.8);
}
.swiper-button-next,
.swiper-button-prev {
  margin-top: -92px;
  i {
    font-size: 40px;
    font-weight: bold;
    color: #006acd;
  }
}
.swiper-button-next {
  right: 8%;
}
.swiper-button-prev {
  left: 8%;
}
.swiper-button-next {
  // background: url("../../../assets/images/icon_right.png");
  background: none;
  width: 50px;
  height: 50px;
}
.swiper-button-prev {
  // background: url("../../../assets/images/icon_left.png");
  background: none;
  width: 50px;
  height: 50px;
}
.custom_box {
  height: 48px;
  display: flex;
  // margin-right: 60px;
  justify-content: flex-end;
  margin: 30px 15px 60px 0;
  padding-bottom: 90px;
  .el-card {
    width: 420px;
    height: 60px;
    line-height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: 800;
    color: #595959;
  }
  .button_yiliao {
    width: 120px;
    height: 60px;
    background: #006acd;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    font-weight: 800;
    color: #ffffff;
  }
}
.solutionIndex {
  height: auto;
  // background: #f5f5f5;
  // padding:30px 0;
  // background: url("../../assets/images/service/Rectangle.png") 100% 100%;
}
.nav {
  padding-top: 120px;
  padding-bottom: 30px;
}
.desc {
  padding: 5px 0;
  font-size: 14px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #575757;
  line-height: 28px;
  letter-spacing: 1px;
  text-indent: 40px;
}
</style>
